<template>
  <div
    :class="`graphs paper-background-flat category-pie ${
      props.name?.includes('keyword') ? 'maire-background-primary-pink' : ''
    }`"
    v-if="!(hideIfEmpty && stats?.length === 0)"
  >
    <p v-if="chartName" class="label strong text-primary s chart-name">{{ chartName }}</p>
    <div
      v-if="(isBarChart || isPieChart) && stats?.length && stats.length > 0"
      style="height: 400px; padding-bottom: 24px"
    >
      <div class="select-field" style="z-index: 12; padding: 12px">
        <span v-if="isBarChart && props.name?.includes('keyword')">
          <MaireButton
            :disabled="!moreAvailable || loading || localLoading"
            @click="fetchMore"
            compact
            text="Fetch More"
          />
        </span>
        <MonthPicker
          @save="handleTimerangeChange"
          :end="{ year: dataDateRange?.latestYear, month: dataDateRange?.latestMonth }"
          :start="{ year: dataDateRange?.firstYear, month: dataDateRange?.firstMonth }"
          :calendarOpenUntil="{
            year:
              allowFuture && dataDateRange?.predictionYear
                ? dataDateRange.predictionYear
                : dataDateRange?.latestYear,
            month:
              allowFuture && dataDateRange?.predictionMonth
                ? dataDateRange.predictionMonth
                : dataDateRange?.latestMonth,
          }"
          :defaultTimeframeText="defaultTimeframeText"
          :forceSingleMonth="field === 'trending'"
        />
      </div>
      <Transition name="slide">
        <div style="height: 100%" v-if="isPieChart">
          <PieChart
            :stats="stats"
            :postFix="postFixForFields(field)"
            :field="field"
            :loading="loading || localLoading"
            :label="chartName"
            :legendLocation="fullscreen ? 'left' : 'bottom'"
            @select="onSelect"
            @unselect="onUnSelect"
          />
          <div class="full maire-drop-shadow paper-background" v-if="fullscreen">
            <div class="close-modal-btn" @click="toggleFullscreen">
              <v-icon>fal fa-times-circle</v-icon>
            </div>
            <p v-if="chartName" class="label strong text-primary s chart-name">{{ chartName }}</p>
            <PieChart
              :stats="stats"
              :postFix="postFixForFields(field)"
              :field="field"
              :loading="loading || localLoading"
              :label="chartName"
              :fullscreen="fullscreen"
              @select="onSelect"
              @unselect="onUnSelect"
            />
          </div>
        </div>
      </Transition>
      <Transition name="slide">
        <div style="height: 100%" v-if="isBarChart && field">
          <BarChart
            :stats="stats"
            :id="id"
            :page="page"
            :postFix="postFixForFields(field)"
            :field="field"
            :loading="loading || localLoading"
            :label="chartName"
            :descending="descending"
            @select="onSelect"
            @unselect="onUnSelect"
            @close-modal="toggleFullscreen"
            @fetch-more="fetchMore"
          />
          <div class="full maire-drop-shadow paper-background" v-if="fullscreen">
            <div class="close-modal-btn" @click="toggleFullscreen">
              <v-icon>fal fa-times-circle</v-icon>
            </div>
            <p v-if="chartName" class="label strong text-primary s chart-name">{{ chartName }}</p>
            <div v-if="noOfFullscreenPages > 1">
              <v-pagination
                density="compact"
                v-model="fullscreenPage"
                :length="noOfFullscreenPages"
                :total-visible="1"
                rounded="circle"
              ></v-pagination>
            </div>
            <div class="maire-scrollbar" style="height: 90%; width: 100%; overflow-y: scroll">
              <div style="padding: 24px; height: 100%">
                <BarChart
                  :stats="stats"
                  :id="id"
                  :page="fullscreenPage"
                  :page-size="100"
                  :postFix="postFixForFields(field)"
                  :field="field"
                  :loading="loading || localLoading"
                  :label="chartName"
                  :descending="descending"
                  @close-modal="toggleFullscreen"
                  @fetch-more="fetchMore"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- BarChart manages the full screen modal separately for now due to it having extra logic around it. -->
      </Transition>
    </div>
    <div v-else-if="isTimelineChart && Object.keys(volumes || {})?.length > 0" style="height: 400px">
      <div class="container-margin" style="height: 100%">
        <div v-if="loading || Object.keys(volumes || {})?.length > 0" class="timeline-chart">
          <TimelineChart
            :stats="volumes"
            :loading="loading || localLoading"
            @select="onSelect"
            :dataDateRange="dataDateRange"
            @unselect="onUnSelect"
          />
          <div
            class="full maire-drop-shadow paper-background"
            v-if="fullscreen"
            style="display: flex; flex-direction: column"
          >
            <div class="close-modal-btn" @click="toggleFullscreen">
              <v-icon>fal fa-times-circle</v-icon>
            </div>
            <p v-if="chartName" class="label strong text-primary s chart-name">{{ chartName }}</p>
            <TimelineChart
              :stats="volumes"
              :loading="loading || localLoading"
              @select="onSelect"
              :dataDateRange="dataDateRange"
              @unselect="onUnSelect"
            />
          </div>
        </div>
        <div v-else>
          <p class="s center">No labels present in selected keywords</p>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="loading || localLoading">
        <WaitAnimation message="Analyzing" />
      </div>
      <p v-else class="s center">No labels present in selected keywords</p>
    </div>
    <div class="chart-buttons" style="width: 100%" v-show="!localLoading && !loading">
      <div v-if="isBarChart && noOfPages > 1" class="widget-action-button">
        <v-pagination
          density="compact"
          v-model="page"
          :length="noOfPages"
          :total-visible="1"
          rounded="circle"
        ></v-pagination>
      </div>
      <div v-if="!isTimelineChart" class="widget-action-button" @click="descending = !descending">
        <v-icon>{{ descending ? "far fa-arrow-down-wide-short" : "far fa-arrow-up-wide-short" }}</v-icon>
      </div>
      <div v-if="!isTimelineChart" class="widget-action-button" @click="switchChartType">
        <v-icon>{{ isPieChart ? "far fa-bar-chart" : "far fa-pie-chart" }}</v-icon>
      </div>
      <div class="widget-action-button" @click="toggleFullscreen">
        <v-icon>far fa-up-right-and-down-left-from-center</v-icon>
      </div>
      <slot></slot>
    </div>
    <div v-show="fullscreen && !isBarChart" class="backdrop"></div>
  </div>
</template>
<style scoped>
.backdrop {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  z-index: 9998;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
}
.full {
  position: fixed;
  width: 80%;
  height: 70vh;
  padding-bottom: 30px;
  margin: auto;
  left: 10%;
  top: 10%;
  max-height: 90%;
  z-index: 9999;
}
.close-modal-btn {
  color: rgb(var(--v-theme-mairePurple));
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 12px;
}
.chart-name {
  margin-right: 200px;
}
.slide-leave-active,
.slide-enter-active {
  transition: opacity 0.5s ease-in-out;
}
.slide-leave-active {
  height: 0px !important;
  opacity: 0;
}
.slide-enter-from,
.slide-leave-to {
  opacity: 0;
}
.slide-leave-to {
  height: 0px !important;
}
.label {
  margin-bottom: 12px;
}
.graphs {
  padding-top: 24px;
  padding-right: 24px;
  /* padding-bottom: 70px; */
}
.select-field {
  z-index: 2;
  position: absolute;
  right: 12px;
  top: 0px;
  display: block;
  display: flex;
  column-gap: 4px;
}
.chart-buttons {
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}
.widget-action-button i {
  font-size: 1rem !important;
}
.widget-action-button {
  color: rgba(var(--v-theme-mairePurpleSecondary));
  cursor: pointer;
}
.container-margin {
  margin-top: 12px;
  margin-bottom: 12px;
}
.category-pie {
  position: relative;
  height: 100%;
}
.category-pie h3 {
  max-height: 400px;
}
.timeline-chart {
  height: 100%;
}
.loading {
  filter: blur(4px) brightness(100%);
}
</style>
<script lang="ts" setup>
import { computed, Ref, ref, watch, onMounted, defineProps, PropType } from "vue";
// import MaireSelect from "@/components/MaireSelect/MaireSelect.vue";
import PieChart from "@/components/PieChart/PieChart.vue";
import BarChart from "@/components/BarChart/BarChart.vue";
import WaitAnimation from "../WaitAnimation/WaitAnimation.vue";
import TimelineChart from "@/components/TimelineChart/TimelineChart.vue";
// import { computed, defineComponent, Ref, ref } from "vue";
// import MaireButton from "@/components/ButtonBlock/MaireButton.vue";
import MonthPicker, {
  DEFAULT_COMPARISON_TYPE,
  DEFAULT_PRESET,
  timeFrameFromSelectedPreset,
  TimeRange,
} from "@/components/MonthPicker/MonthPicker.vue";
import { useStore, WidgetType } from "@/store";
import { yearMonthKeysInRange, postFixForFields, getEffectiveFilter } from "./helpers";
import MaireButton from "../ButtonBlock/MaireButton.vue";

const props = defineProps({
  volumes: {
    type: Object,
    required: false,
  },
  params: {
    type: Object,
    required: false,
    default: () => {},
  },
  dataDateRange: {
    type: Object,
    required: false,
    properties: {
      latestYear: {
        type: Number,
        required: false,
      },
      latestMonth: {
        type: Number,
        required: false,
      },
      firstYear: {
        type: Number,
        required: false,
      },
      firstMonth: {
        type: Number,
        required: false,
      },
    },
  },
  onSelect: {
    type: Function,
    required: false,
    default: () => {
      console.log("onSelect not implemented");
    },
  },
  onUnSelect: {
    type: Function,
    required: false,
    default: () => {
      console.log("onUnSelect not implemented");
    },
  },
  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
  hideIfEmpty: {
    type: Boolean,
    required: false,
    default: false,
  },
  defaultField: {
    type: String,
    required: true,
  },
  id: {
    type: Number,
    required: true,
  },
  itemType: {
    type: String,
    required: true,
    default: "category",
  },
  name: {
    type: String,
    required: false,
  },
  labels: {
    type: Array as PropType<string[]>,
    required: false,
  },
  defaultTimeframeText: {
    type: String,
    required: false,
  },
  initialType: {
    default: WidgetType?.BarChart,
    required: false,
  },
  discovery: {
    type: Boolean,
    required: false,
    default: false,
  },
  allowFuture: {
    type: Boolean,
    required: false,
    default: false,
  },
  kwIds: {
    type: Array as PropType<number[]>,
    required: false,
  },
});
const store = useStore();
const field = ref("");
const page = ref(1);
const fullscreenPage = ref(1);
const noOfPages = computed(() => Math.ceil(stats.value.length / 10));
const noOfFullscreenPages = computed(() => Math.ceil(stats.value.length / 100));
const labelCountMap: Ref<Record<string, number>> = ref({});
const type = ref(WidgetType.BarChart);
const selectedRange = ref(undefined as TimeRange | undefined); // { start: { year: 2023, month: 11 }, end: { year: 2024, month: 10 }
const comparisonRange = ref(undefined as TimeRange | undefined);
const selectedPreset = ref(DEFAULT_PRESET);
const selectedComparisonType = ref(DEFAULT_COMPARISON_TYPE);
const labelTotals: Ref<Record<string, Record<string, number>>> = ref({});
const fullscreen = ref(false);
const localLoading = ref(false);
// Computed properties
const filters = computed(() => store.state.analysis.currentFilters);
const chartName = computed(() => {
  if (props.name) {
    return props.name;
  } else {
    return `${props.defaultField} by ${props.itemType}`;
  }
});
const stats = computed(() => {
  return Object.entries(labelTotals.value).map(([label, totals]) => {
    const { total, comparison, maxComparison } = totals;
    return {
      label,
      volume: total,
      growth: comparison > 0 ? (total / comparison - 1) * 100 : total > 0 ? 100 : 0,
      absoluteGrowth: total - comparison,
      trending: field.value === "trending" && maxComparison > 0 ? (total / maxComparison - 1) * 100 : 0,
      count: props.itemType === "category" ? labelCountMap.value?.[label] : 1,
    };
  });
});
const isPieChart = computed(() => {
  return type.value === WidgetType.PieChart;
});
const isBarChart = computed(() => {
  return type.value === WidgetType.BarChart;
});
const isTimelineChart = computed(() => {
  return type.value === WidgetType.TimelineChart;
});

// Watchers
watch(() => JSON.stringify(props.volumes), recalculateStats);
watch(
  () => JSON.stringify({ filters: getEffectiveFilter(filters.value), labels: props.labels }),
  () => {
    if (props.name?.includes("keyword") && !props.discovery) {
      refetchStats();
    }
  },
  { immediate: true }
);
watch(
  () => props.kwIds,
  () => {
    refetchStats();
  }
);

// On mounted
onMounted(() => {
  if (props.initialType) {
    type.value = props.initialType;
  }
  field.value = props.defaultField;
  const { timeframe, comparison } = timeFrameFromSelectedPreset(
    undefined,
    field.value === "trending" ? 0 : 11,
    {
      year: props.dataDateRange?.latestYear,
      month: props.dataDateRange?.latestMonth,
    },
    field.value === "trending"
  );
  selectedRange.value = timeframe;
  comparisonRange.value = comparison;
  if (props.name?.includes("keyword")) {
    refetchStats();
  } else {
    recalculateStats();
  }
});
function toggleFullscreen() {
  // attach listener for escape key
  if (!fullscreen.value) {
    document.addEventListener(
      "keydown",
      (event: KeyboardEvent) => event.key === "Escape" && (fullscreen.value = false)
    );
  } else {
    document.removeEventListener(
      "keydown",
      (event: KeyboardEvent) => event.key === "Escape" && (fullscreen.value = false)
    );
  }
  fullscreen.value = !fullscreen.value;
}
const descending = ref(true);
watch(descending, () => {
  if (props.name?.includes("keyword") && !props.discovery) {
    refetchStats();
  }
});

const noOfSeries = computed(() => Object.keys(labelTotals.value).length);
const moreAvailable = computed(() => {
  return noOfSeries.value % 100 === 0;
});
function fetchMore() {
  if (moreAvailable.value) {
    const nextPage = Math.ceil(noOfSeries.value / 100 + 1);
    refetchStats(nextPage);
  }
}
function refetchStats(fetchPage?: number) {
  if (!selectedRange.value?.end?.year) return;
  localLoading.value = true;
  if (props.discovery) {
    store
      .dispatch("analysis/fetchKwStatsForKwIds", {
        timerange: selectedRange.value,
        comparison: comparisonRange.value,
        field: field.value,
        kwIds: props.kwIds,
        ...props.params,
        preset: selectedPreset.value,
        selectedComparisonType: selectedComparisonType.value,
        descending: descending.value,
      })
      .then((r) => {
        if (r) {
          localLoading.value = false;
          labelTotals.value = Object.fromEntries(r.map((stat: any) => [stat.text, stat]));
        }
      })
      .catch(() => {})
      .finally();
    return;
  }
  store
    .dispatch("analysis/fetchAnalysisKwStats", {
      timerange: selectedRange.value,
      comparison: comparisonRange.value,
      field: field.value,
      labels: props.labels,
      ...props.params,
      preset: selectedPreset.value,
      selectedComparisonType: selectedComparisonType.value,
      descending: descending.value,
      page: fetchPage,
    })
    .then((r) => {
      if (r) {
        const newData = Object.fromEntries(r.map((stat: any) => [stat.text, stat]));
        if (fetchPage) {
          labelTotals.value = { ...labelTotals.value, ...newData };
        } else {
          page.value = 1;
          labelTotals.value = newData;
        }
      }
      localLoading.value = false;
    })
    .catch((e) => {
      console.log(e);
      localLoading.value = false;
    });
}
function recalculateStats() {
  // if (props.loading) return; // do not recalculate stats while loading, they will change
  if (!props.volumes) return;
  if (!selectedRange.value?.start?.month || !selectedRange.value?.end?.month) return;
  if (!comparisonRange.value?.start?.month || !comparisonRange.value?.end?.month) return;
  const newLabelTotals = {} as Record<string, Record<string, number>>;
  const monthsToCheck = yearMonthKeysInRange(selectedRange.value);
  const comparisonMonthsToCheck = yearMonthKeysInRange(comparisonRange.value);
  Object.entries(props.volumes).forEach(([label, volumes]) => {
    if (!volumes) return;
    newLabelTotals[label] = { total: 0, comparison: 0, maxComparison: 0 };
    for (const { year, month } of monthsToCheck) {
      newLabelTotals[label].total += volumes[year]?.[month - 1] ?? 0;
    }
    for (const { year, month } of comparisonMonthsToCheck) {
      const compVolume = volumes[year]?.[month - 1] ?? 0;
      newLabelTotals[label].comparison += compVolume;
      if (field.value === "trending" && compVolume > newLabelTotals[label].maxComparison) {
        newLabelTotals[label].maxComparison = compVolume;
      }
    }
  });
  labelTotals.value = newLabelTotals;
}

function handleTimerangeChange({
  timeframe,
  comparison,
  preset,
  comparisonType,
}: {
  timeframe: TimeRange;
  comparison: TimeRange;
  preset: string;
  comparisonType: string;
}) {
  selectedRange.value = timeframe;
  comparisonRange.value = comparison;
  selectedPreset.value = preset;
  selectedComparisonType.value = comparisonType;
  if (props.name?.includes("keyword")) {
    refetchStats();
    return;
  } else {
    recalculateStats();
  }
}
function switchChartType() {
  type.value = type.value === WidgetType.PieChart ? WidgetType.BarChart : WidgetType.PieChart;
}
</script>
