<template>
  <div>
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" />
    <link href="https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Nunito Sans:wght@400;800" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Plus Jakarta Sans" rel="stylesheet" />
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />
    <v-layout>
      <div style="z-index: 2"><SideBar /></div>
      <v-main :class="`main_content ${fullScreenRoute ? '' : 'bg'}`" style="min-height: 100vh">
        <div id="app_wait_animation" v-if="loadingApp">
          <WaitAnimation message="Loading the essentials" />
        </div>
        <Transition name="notification">
          <MaireNotification
            v-if="errorMessage"
            type="error"
            :handle-click="clearError"
            :message="errorMessage"
          />
        </Transition>
        <Transition name="notification">
          <MaireNotification
            v-if="successMessage"
            type="success"
            :handle-click="clearSuccess"
            :message="successMessage"
          />
        </Transition>
        <div class="router-view" :style="rightNavbarOpen ? { '--padding': '350px' } : { '--padding': '0px' }">
          <ConsentModal v-if="user.email && !user.consent && !user.impersonator" />
          <router-view v-if="(user.email && user.consent) || fullScreenRoute || user.impersonator" />
        </div>
      </v-main>
    </v-layout>
  </div>
</template>
<style>
.timeline-chart .apexcharts-toolbar {
  max-width: 250px;
}
.noevents {
  pointer-events: none;
}
#main-navbar {
  overflow: hidden;
}
#logout {
  overflow: hidden;
}
.router-view {
  transition: padding 0.3s ease-in-out;
  padding-right: var(--padding);
}
.chip {
  border-radius: 4px;
  padding: 2px 8px;
  margin-left: 12px;
  display: inline-block;
}
.google-only {
  background-color: rgba(var(--v-theme-info), 1);
  color: white;
}
.shared-by-chip {
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 1);
  color: white;
}
.shared-by-chip i {
  margin-right: 0px;
  font-size: 18px;
}
.tableBody .v-input__details {
  display: none;
}
.wait-animation {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  padding-top: 20%;
  width: 100%;
  height: 100%;
}
.strong {
  font-weight: 800;
}
.pointer {
  cursor: pointer;
}
.column-flex {
  height: 100%;
  display: flex;
  flex-direction: column;
}
#app_wait_animation {
  background-color: white;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  padding-top: 20%;
  width: 100%;
  height: 100%;
}
#navbar {
  padding-left: 12px;
  padding-right: 12px;
}

#navbar img {
  border-radius: 100%;
  aspect-ratio: 1/1;
  pointer-events: none;
}
#navbar .v-list-item__prepend {
  display: block;
}
.v-list-item-title {
  font-size: 0.8rem !important;
}
.drawer-open-button {
  position: absolute;
  top: 40%;
  right: -11px;
  width: 11px !important;
  background-color: rgb(var(--v-theme-mairePurpleSecondary));
  color: white;
  height: 60px;
  cursor: pointer;
  transition: border-radius 0.3s ease, right 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
.drawer-open-button-left {
  position: absolute;
  top: 40%;
  left: 0px;
  width: 11px !important;
  background-color: rgb(var(--v-theme-mairePurpleSecondary));
  color: white;
  height: 60px;
  cursor: pointer;
  transition: border-radius 0.3s ease, right 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
.drawer-open-button-up {
  position: absolute;
  bottom: -11px;
  right: 0px;
  width: 60px !important;
  background-color: rgb(var(--v-theme-mairePurpleSecondary));
  color: white;
  height: 11px;
  cursor: pointer;
  transition: border-radius 0.3s ease, right 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 8px 8px;
}
.drawer-open-button-up i {
  margin-top: -16px;
}

.nav-btn-open {
  right: -11px;
  border-radius: 0% 8px 8px 0%;
  z-index: 2;
}

.nav-btn-close {
  right: 0px;
  border-radius: 8px 0% 0% 8px;
  z-index: 2;
}

.nav-btn-open-left {
  left: 11px;
  border-radius: 0% 8px 8px 0%;
  z-index: 2;
}

.nav-btn-close-left {
  left: 0px;
  border-radius: 8px 0% 0% 8px;
  z-index: 2;
}

.v-label {
  font-family: "Nunito Sans", sans-serif !important;
  font-size: 0.7rem;
}

.notification-leave-active,
.notification-enter-active {
  transition: all 0.3s ease-in-out;
}

.notification-enter-from,
.notification-leave-to {
  transform: translateX(200px);
  opacity: 0;
}

.bottom-padding-20 {
  padding-bottom: 20px !important;
}
.avatar {
  margin-left: -12px;
  margin-right: -12px;
}

.maire-link {
  color: #8200ff;
  cursor: pointer;
}

.v-breadcrumbs-item--disabled {
  font-weight: bolder;
  opacity: 1;
  color: rgb(var(--v-theme-maireDarkBlue));
}

.v-field--variant-outlined {
  color: rgba(41, 54, 87, 0.5);
}

.inputs .v-field--variant-outlined input {
  color: white;
}

.inputs.light .v-field--variant-outlined input,
.inputs .v-autocomplete__selection-text {
  color: rgb(var(--v-theme-maireDarkBlue));
}
.company-preferences .v-autocomplete__selection-text,
.company-preferences .v-select__selection-text,
.company-preferences input {
  color: white;
}
.inputs .v-field__prepend-inner {
  margin-right: 8px;
}
.inputs .v-autocomplete__selection {
  color: white;
}
.inputs.label-name-input input {
  color: rgb(var(--v-theme-maireDarkBlue));
}
.inputs.dark .v-autocomplete__selection,
.inputs.dark .v-field--variant-outlined input {
  color: black;
}

.inputs .v-field-label {
  color: rgb(var(--v-theme-maireTeal));
}

.inputs i {
  color: rgb(var(--v-theme-maireTeal));
  opacity: 0.5;
}
.icon-gap {
  display: flex;
  align-items: center;
  gap: 4px;
}

#action-bar .add-level-button i {
  color: rgba(var(--v-theme-mairePurpleSecondary), 1);
  opacity: 1;
}

#action-bar .title input {
  font-size: 14px;
  padding-top: 0px !important;
}

#action-bar .v-input--density-default {
  --v-input-padding-top: inherit;
}
#action-bar .actions .noborder .v-btn--variant-outlined {
  border: 1px solid transparent;
}
#action-bar .actions .close-btn .v-btn--variant-outlined {
  color: white;
  border: 1px solid white;
}

.discovery-name-field .v-field__field {
  padding: 8px 12px 8px 0px;
  border-radius: 8px;
}

.discovery-name-field .v-field__field:hover:not(.v-field--focused),
.discovery-name-field .v-field--focused {
  background-color: rgba(255, 255, 255, 0.15);
}

.v-input--density-default .v-field--no-label {
  --v-field-padding-bottom: 0px;
}
.inputs i.fa-regular {
  color: rgb(var(--v-theme-maireTeal));
  opacity: 0.5;
}
.inputs .level-toggle i {
  color: inherit;
}

.inputs .v-field__prepend-inner > .v-icon {
  opacity: 1;
}
.inputs .v-field__input {
  font-size: 0.8rem;
}
.inputs .v-field-label {
  font-size: 0.6em;
  line-height: 1.2rem;
}

.inputs input {
  font-size: 0.8rem;
}
.rule-chip .v-selection-control--density-default {
  --v-selection-control-size: inherit;
  margin-right: 4px;
  font-size: 10px;
}
#label-view input.v-field__input {
  background-color: white;
  border-radius: 10px;
}
#action-bar label,
#action-bar input {
  color: rgb(var(--v-theme-grey));
}

#action-bar .v-autocomplete__selection {
  display: none;
}

.centeredIcon {
  border-radius: 25px;
  margin: 5px;
  padding: 0px;
  text-align: center;
  outline-offset: 0.1em;
}

.inputs .v-field--variant-outlined .v-field__outline__start {
  border-radius: 10px 0px 0px 10px;
}
.inputs .v-field--variant-outlined .v-field__outline__end {
  border-radius: 0px 10px 10px 0px;
}
.search_container .v-field--variant-outlined .v-field__outline__start {
  border-radius: 8px 0px 0px 8px;
}
.search_container .v-field--variant-outlined .v-field__outline__end {
  border-radius: 0px 8px 8px 0px;
}

.search_container .v-input__control {
  border-radius: 8px;
  background-color: white;
}

.search_bar .v-input__control input {
  color: rgb(var(--v-theme-maireDarkBlue));
}
.search_bar .v-field__clearable i.fa-times-circle {
  color: rgb(var(--v-theme-maireDarkBlue)) !important;
}

.performance_table .cell-keyword {
  max-width: 150px;
}
.cell-labels p {
  color: rgb(var(--v-theme-maireDarkBlue)) !important;
}
.v-field--variant-outlined .v-field__outline__start,
.v-field--variant-outlined .v-field__outline__notch::before,
.v-field--variant-outlined .v-field__outline__notch::after,
.v-field--variant-outlined .v-field__outline__end {
  /* border: 2px #14CCC9 solid !important; */
  color: #14ccc9;
}

.slider .v-field--variant-outlined .v-field__outline__start {
  display: none;
}
.slider .v-field--variant-outlined .v-field__outline__end {
  border-style: none;
  border-radius: 10px;
  border: 1px solid rgb(var(--v-theme-maireTeal));
}
/**show underline */
.slider input {
  color: black;
  text-align: center;
  font-size: 12px;
}
.slider .v-field .padding-start,
.slider .v-field .padding-end,
.slider .v-field .padding-top,
.slider .v-field .padding-bottom {
  padding: 0px !important;
}
.slider .v-input__details {
  display: none !important;
}

/* hide input number arrows on chrome */
.slider input::-webkit-outer-spin-button,
.slider input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.slider input[type="number"] {
  -moz-appearance: textfield;
}
.slider .v-slider.v-input--horizontal {
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.maire-checkbox .v-input--horizontal .v-input__prepend {
  margin-inline-end: 0px;
}

.v-chip__underlay {
  background: rgba(0, 0, 0, 0) !important;
}

.v-chip__close i {
  color: rgba(255, 255, 255, 0.7) !important;
}

.paper-background {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.paper-background-flat {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
}

.paper-background-flat-no-padding {
  background-color: #fff;
  border-radius: 8px;
}

.paper-background-no-bg {
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.padding-sm {
  padding: 12px;
}

.padding-med {
  padding: 24px;
}

.padding-lg {
  padding: 36px;
}

.maire-drop-lg {
  padding: 36px;
}

.maire-drop-shadow {
  box-shadow: 0px 0px 20px rgba(var(--v-theme-mairePurpleSecondary), 0.07);
}

.maire-drop-shadow-intense {
  box-shadow: 0em 0em 1em rgba(var(--v-theme-mairePurpleSecondary), 0.3);
}

.nopadding {
  padding: 0px !important;
}
.mairePurpleSecondary {
  color: rgb(var(--v-theme-mairePurpleSecondary));
}
.bg-maire-purple {
  background-color: rgb(var(--v-theme-mairePurple)) !important;
}

.center {
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: center;
}

.float-right {
  position: relative;
  float: right;
  right: 0px !important;
}

.float-right button {
  float: right;
}
.white-icon .v-input__prepend > .v-icon,
.v-input__append > .v-icon {
  opacity: 1;
}
.medium-icon i {
  font-size: 20px;
}
.maire-violet {
  color: #b500ff;
  opacity: 35%;
}

.maire-purple {
  color: #8200ff;
}

.maire-dark-blue {
  color: #293657;
}

.maire-blue {
  color: rgb(var(--v-theme-maireDarkBlue));
}
.maire-grey-0 {
  color: rgb(var(--v-theme-maireGrey0));
}
.blue-input input {
  color: rgb(var(--v-theme-maireDarkBlue)) !important;
}

.maire-teal {
  color: #14ccc9;
}

.maire-pink {
  color: #ff73ff;
}

.maire-secondary-yellow {
  color: #ffd100;
}

.maire-secondary-ocean {
  color: #4fe0b5;
}

.maire-secondary-pink {
  color: #e500ff;
}

.maire-secondary-violet {
  color: #b500ff;
}

.maire-background-light {
  background-color: #ffffff;
}

.maire-background-light-2 {
  background-color: #f4f5f6;
}

.maire-background-dark {
  background-color: #293657;
}

.maire-background-primary-purple {
  background-color: #f8f2ff;
}

.maire-background-primary-pink {
  background-color: #fff8ff;
}

.maire-text-light {
  color: #ffffff;
}

.maire-text-dark {
  color: #232323;
}

.maire-text-highlight {
  color: #8200ff;
}

.maire-text-link {
  color: #e500ff;
}

.plain-title {
  color: #8200ff;
  font-size: 14px;
  line-height: 24px;
}

.plain-btn {
  border: 1px #b500ff solid;
  color: #b500ff !important;
  cursor: pointer;
  border-radius: 0px;
  margin-bottom: 20px;
  margin-left: 12px;
}

.plain-btn:hover {
  color: #8200ff !important;
  border: 1px #8200ff solid;
}

.plain-btn.inverse {
  color: white !important;
}

.plain-btn img {
  margin-bottom: -2px;
  padding-right: 5px;
}

.Evaluatory {
  background: #38026c !important;
}

.Actional {
  background: rgb(124, 80, 166) !important;
}

.Illustrative {
  background: rgb(105, 0, 206) !important;
}

.Explanatory {
  background: rgb(130, 0, 255) !important;
}

.Reasoning {
  background: rgb(147, 35, 255) !important;
}

.Statistical {
  background: rgb(163, 68, 255) !important;
}

.Product\/Service {
  background: rgb(188, 119, 255) !important;
}

.Product {
  background: rgb(188, 119, 255) !important;
}

.inline {
  display: inline;
}

h1,
h2,
input,
span,
h3 {
  font-family: "Nunito Sans", sans-serif;
}

h3 {
  font-family: "Nunito Sans", sans-serif;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: 0px;
  text-align: left;
}

strong {
  color: rgb(41, 54, 87);
}

p {
  /* styleName: Maire/body; */
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: regular;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-align: left;
  color: rgb(41, 54, 87);
}
h1 {
  font-size: 38px;
  color: rgb(41, 54, 87);
}
h2 {
  font-size: 32px;
  font-family: "Nunito Sans", sans-serif;
  color: rgb(41, 54, 87);
  line-height: 34px;
  font-weight: 800;
  padding: 12px;
}
input {
  font-family: "Nunito Sans" !important;
}
.l {
  font-size: 36px;
  font-family: "Nunito Sans", extrabold;
}

.m {
  font-size: 1.2rem;
  font-family: "Nunito Sans", extrabold;
}

.s {
  font-size: 1rem;
  font-family: "Nunito Sans", extrabold;
}

.xs {
  font-size: 0.8rem;
  font-family: "Nunito Sans", bold;
}

.xxs {
  font-size: 0.6rem;
  line-height: 14px;
  font-family: "Nunito Sans", bold;
}

.bg {
  background-color: #f7f7f7;
}

.v-list-item {
  /* margin-top: 10px !important; */
  padding: 12px;
  min-height: 0px !important;
}
.v-list-item--density-compact.v-list-item--one-line {
  min-height: 32px;
}
.v-list-item--one-line {
  min-height: 32px;
}
.v-list-item--density-comfortable.v-list-item--one-line {
  min-height: 32px;
}

/* 
.v-list-item i {
  margin-right: 10px;
} */

.v-list-item--nav .v-list-item-title {
  font-weight: 400;
  line-height: 12px;
}
.v-btn span {
  font-size: 0.7rem;
  font-weight: 700;
  text-transform: none;
}
.v-list-item--nav .v-list-item--density-compact.v-list-item--one-line {
  min-height: 10px !important;
}

.smaller-font .v-list-item-title {
  font-size: 12px !important;
  line-height: 12px !important;
}
.v-list-item--nav i {
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
}
.analysis-nav-list {
  position: relative;
  max-height: 40vh;
  overflow: scroll;
  scrollbar-width: none;
}
.analysis-view-toggle-icon {
  position: relative;
  display: inline;
  float: right;
  margin: auto;
  margin-right: -12px;
  padding: 10px 16px;
  border-radius: 36px;
  font-size: 12px;
  transition: background-color 0.3s ease-in-out;
}
.analysis-view-toggle-icon:hover {
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 0.2);
}
i.analysis-view-toggle-icon {
  text-align: center;
  display: table;
}
.v-list-item--active {
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 0.05);
  color: rgb(var(--v-theme-mairePurpleSecondary));
  --v-theme-overlay-multiplier: 0;
}

.light-purple-background {
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 0.05) !important;
}
#navbar .v-list-item--active {
  /* Neutralize default active styles */
  /* background-color: transparent !important;  Or any other style reset */
  box-shadow: none !important; /* Or any other style reset */
  color: inherit !important; /* Or any other style reset */
}

/* #navbar .v-list {
  background-color: transparent;
} */
#navbar .sublist {
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 0.05) !important;
}

#navbar .active-item {
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 0.05);
  color: rgb(var(--v-theme-mairePurpleSecondary));
}

#viewlist .v-list-item--active div {
  color: inherit !important;
}

#navbar .list-no-margin {
  margin-left: 12px;
  margin-top: -12px;
}
#navbar {
  font-size: 12px;
}
#navbar .item:has(.active) {
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 0.05);
}

.v-list-item--active div,
.v-list-item--active i {
  color: rgb(var(--v-theme-mairePurpleSecondary));
}

.v-list-item:hover {
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 0.05);
  --v-theme-overlay-multiplier: 0;
}

#user-avatar.v-list-item:hover {
  background-color: rgba(0, 0, 0, 0);
  --v-theme-overlay-multiplier: 0;
}

.v-label {
  opacity: 100 !important;
}

.mdi:mdi-radiobox-blank::before {
  content: "\F043D";
  color: rgb(var(--v-theme-mairePurpleSecondary));
}

.mdi:mdi-radiobox-marked::before {
  content: "\F043E";
  color: rgb(var(--v-theme-mairePurpleSecondary));
}

/* .v-navigation-drawer__content {
  padding: 20px !important;
} */

.v-navigation-drawer {
  border: none;
  box-shadow: inset 0px 0px 40px rgba(130, 0, 255, 0.05);
  padding-top: 50px !important;
}
/* .v-navigation-drawer .v-list {
  overflow: auto;
} */

textarea#title-ghost {
  padding: 12px 12px 12px 0px !important;
  overflow: visible;
  padding-bottom: 34px !important;
  margin-bottom: -34px;
}

#title-ghost-h2 {
  font-size: 24px;
  font-family: "Nunito Sans", sans-serif;
  color: rgb(41, 54, 87);
  line-height: 24px;
  font-weight: 800;
  padding: 12px;
  padding-left: 0px !important;
  margin-bottom: -54px;
  padding-bottom: -20px !important;
}

#title-ghost-h2 .v-input__details {
  display: none;
}

#maire-tab .content .v-input__details {
  display: none;
}

#titleselector-radio-group-container .v-input__details {
  display: none;
}

#title-ghost-h3 {
  font-size: 18px;
  font-family: "Nunito Sans", sans-serif;
  color: rgb(41, 54, 87);
  line-height: 20px;
  font-weight: 600;
  padding: 12px 0px 0px 0px !important;
  width: 100%;
  outline: none;
  resize: none;
  /* padding: 12px 30px -20px 0px !important;
  padding-bottom: 0px !important;
  margin-bottom: -50px !important; */
}

.structure {
  font-family: "Nunito Sans", sans-serif;

  /* line-height: 24px; */
  /* padding: 12px; */
}

.title-h2 {
  font-size: 32px;
  line-height: 34px;
  font-weight: 800;
}

.title-h3 {
  font-size: 24px !important;
  line-height: 54px !important;
  letter-spacing: 0px !important;
}

.abstract textarea {
  margin-bottom: -20px !important;
}

.v-container {
  max-width: 100%;
}

.debug_buttons {
  position: absolute;
  right: 0px;
  bottom: 30px;
}

#split-view-container textarea,
#split-view-container h3 {
  padding-right: 40px;
}

.structure textarea {
  padding-top: 10px !important;
  padding-bottom: 30px !important;
  font-family: Nunito Sans;
  color: rgb(41, 54, 87);
  letter-spacing: 0.01em;
  font-size: 14px;
}

.structure .v-input__details {
  display: none;
}

.abstract textarea {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-family: Nunito Sans;
  color: rgb(41, 54, 87);
  letter-spacing: 0.01em;
  font-size: 14px;
}

.logout-button {
  font-size: 12px;
  height: 20px !important;
  padding-top: 30px;
  padding-left: 24px;
  cursor: pointer;
}

.apexcharts-zoom-icon.apexcharts-selected svg {
  fill: #293657 !important;
}

.apexcharts-pan-icon.apexcharts-selected svg {
  stroke: #293657 !important;
}

.apexcharts-tooltip-title {
  background-color: white !important;
}

.apexcharts-xaxistooltip {
  visibility: hidden;
}

/* these are to make the legends for apex charts inline. Default is one per row (ugly) */
.category-pie .apexcharts-legend.apx-legend-position-left {
  flex-direction: row !important;
  position: relative !important;
  flex-wrap: wrap !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}
.category-pie .apexcharts-legend-series {
  display: inline-block !important;
  min-width: 0px !important;
  margin-top: 12px !important;
  margin-right: 12px !important;
}

.apexcharts-legend {
  max-height: 100%; /* adjust accordingly to your requirements */
  overflow-y: auto; /* to make it scrollable */
}
div[seriesname="Impressions"] span {
  font-size: 12px !important;
  padding: 8px 15px !important;
  color: white !important;
  border-radius: 5px;
  text-align: center;
  background-color: rgba(var(--v-theme-maireTeal), 0.6) !important;
}
div[seriesname="Impressions"] span {
  color: white !important;
}

div[seriesname="Impressions"] .apexcharts-legend-marker,
div[seriesname="Clicks"] .apexcharts-legend-marker {
  display: none !important;
}
div[seriesname="Clicks"] span {
  font-size: 12px !important;
  padding: 8px 15px;
  margin-left: 0px;
  color: white !important;
  border-radius: 5px;
  text-align: center;
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 0.6) !important;
}
div[seriesname="Clicks"] span {
  color: white !important;
}

.assistant p,
.assistant h1,
.assistant h2,
.assistant h3,
.assistant h4 {
  color: white;
  padding-left: 0px;
}

.assistant .message-content {
  padding-left: 0px !important;
}

.assistant .message-content img {
  max-width: 30%;
}

.assistant .message-content h1 {
  font-size: 1.2rem;
  margin-bottom: -20px;
}

.assistant .message-content h2 {
  font-size: 1rem;
  margin-bottom: -20px;
}

.assistant .message-content h3 {
  font-size: 1rem;
}

.assistant .message-content h4 {
  font-size: 0.8rem;
  font-weight: 800;
}

.assistant .message-content ul,
.assistant ol {
  line-height: 100% !important;
}

.message-content {
  margin-bottom: -20px;
  font-family: "Nunito Sans", sans-serif;
}

.message-content pre > code {
  white-space: pre-wrap;
}
#search-widget .apexcharts-bar-area:hover {
  fill: rgba(var(--v-theme-maireTeal), 0.5);
}

.item-row:hover strong,
.item-row:hover i,
.item-row:hover p {
  color: white;
}
.item-row:hover .variant-tooltip {
  display: block !important;
}
.trending-checkboxes .v-input__control {
  height: 40px !important;
  font-size: 14px;
}
.horizontal-padding {
  padding: 0px 48px 0px;
}
.custom-density .v-input--density-compact {
  --v-input-control-height: 10px;
  --v-input-padding-top: 0px;
  --v-input-padding-bottom: 0px;
  --v-field-input-padding-top: 0px;
  --v-field-input-padding-bottom: 0px;
}
.custom-density i {
  --v-icon-size-multiplier: 0.7;
}
.custom-density input {
  font-size: 1em !important;
}
.custom-density label {
  font-size: 0.7em !important;
}
.custom-density .v-field__input {
  padding-bottom: 0px;
}
.v-icon--size-default {
  --v-icon-size-multiplier: 0.7; /* TODO: Check if adverse effects
    /* font-size: calc(var(--v-icon-size-multiplier)* 1.5em); */
}
.trending-checkboxes label {
  font-size: 14px;
}

.maire-scrollbar {
  max-height: 100%;
  /* padding: 12px; */
  overflow: hidden;
  scrollbar-width: thin; /* 'auto' or 'thin' */
  scrollbar-color: rgb(var(--v-theme-mairePurple)) transparent; /* color of the thumb and track */
}
.maire-scrollbar:hover {
  overflow: auto;
}
/* For Webkit (Chrome, Safari, Edge) */
.maire-scrollbar::-webkit-scrollbar {
  width: 10px; /* Adjust the width of the scrollbar */
  border-radius: 5px; /* Round the corners of the scrollbar */
}

.maire-scrollbar::-webkit-scrollbar-track {
  background: transparent; /* Track background */
  border-radius: 5px;
}

.maire-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(var(--v-theme-mairePurple)); /* Your desired shade of purple */
  border-radius: 5px; /* Round the corners of the scrollbar thumb */
}

.maire-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgb(var(--v-theme-mairePurple)); /* Your desired shade of purple */
}
.v-color-picker-edit__input span {
  color: rgb(var(--v-theme-maireDarkBlue));
}
.v-color-picker-edit button {
  color: rgb(var(--v-theme-maireDarkBlue));
}
@media (max-width: 1400px) {
  .inputs .v-field__input,
  .inputs .v-field-label {
    font-size: 0.7rem;
  }
  .v-icon--size-default i {
    font-size: 4px !important;
  }
  p,
  span,
  input,
  div {
    font-size: 0.7rem;
  }
  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 24px;
  }

  .l {
    font-size: 32px;
    font-family: "Nunito Sans", extrabold;
  }

  .m {
    font-size: 16px;
    font-family: "Nunito Sans", extrabold;
  }

  .s {
    font-size: 12px;
    font-family: "Nunito Sans", extrabold;
  }

  .xs {
    font-size: 0.7rem;
    line-height: 12px;
    font-family: "Nunito Sans", bold;
  }

  .xxs {
    font-size: 6px;
    line-height: 9px;
    font-family: "Nunito Sans", bold;
  }
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import MaireNotification from "./components/MaireNotification/MaireNotification.vue";
import ConsentModal from "./components/ConsentModal/ConsentModal.vue";
import WaitAnimation from "./components/WaitAnimation/WaitAnimation.vue";
import { useStore } from "@/store";
import { useRoute, useRouter } from "vue-router";
import SideBar from "./components/SideBar/SideBar.vue";

type LinkData = {
  name: string;
  icon: string;
  link: string;
  analyses?: Array<any>;
};

export default defineComponent({
  data: () => ({
    rail: true,
    analysisExpanded: false,
    expandedAnalysis: "",
    showAnalysisList: false,
  }),
  errorCaptured(err, instance, info) {
    console.error(err, instance, info);
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    return { store, route, router };
  },
  mounted() {
    if (this.route?.fullPath !== "/") {
      this.store.dispatch("setup");
    }
  },
  computed: {
    user() {
      return this.store.state.user;
    },
    userIsNotViewer() {
      return this.user?.type !== "viewer";
    },
    userIsAdmin() {
      return this.user?.type.includes("admin");
    },
    views() {
      return this.store.state.analysis.views;
    },
    savedFilters() {
      return this.store.state.analysis.analyses;
    },
    loadingApp() {
      return this.store.state.loading.loadingApp;
    },
    links() {
      const links: Array<LinkData> = [
        {
          name: "Analyses",
          icon: "fa-regular fa-key-skeleton",
          link: "/analysis",
          analyses: [...this.savedFilters]
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((filter) => ({
              name: filter.name,
              link: `/analysis/${filter.id}`,
              views: [...filter.views]
                .sort((a, b) => a.name.localeCompare(b.name))
                ?.map((view) => {
                  return {
                    name: view?.name,
                    link: `/analysis/${filter.id}?view=${view.id}`,
                  };
                }),
            })),
        },
      ];

      if (this.userIsNotViewer) {
        links.push(
          ...[
            {
              name: "Discovery",
              icon: "far fa-key-skeleton",
              link: "/keywords",
            },
            {
              name: "Automation",
              icon: "far fa-robot",
              link: "/keywords",
            },
            {
              name: "Settings",
              icon: "fa-regular fa-gear",
              link: "/settings",
            },
          ]
        );
      }

      return links;
    },
    rightNavbarOpen() {
      return this.store.state.rightNavbarOpen && this.route.path.includes("analysis/");
    },
    errorMessage() {
      return this.store.state.errorText;
    },
    successMessage() {
      return this.store.state.successText;
    },
    fullScreenRoute() {
      return this.route.fullPath?.includes("login") || this.route.fullPath?.includes("reset_password");
    },
  },
  methods: {
    clearError() {
      this.store.dispatch("clearErrorText");
    },
    clearSuccess() {
      this.store.dispatch("clearSuccessText");
    },
    logout() {
      this.store.dispatch("logout");
    },
    toggleAnalysis(name: string) {
      this.expandedAnalysis = this.expandedAnalysis === name ? "" : name;
    },
    toggleAnalysisSection() {
      this.analysisExpanded = !this.analysisExpanded;
      if (this.analysisExpanded) {
        this.rail = false;
      } else {
        this.rail = true;
      }
    },
  },
  components: { MaireNotification, WaitAnimation, ConsentModal, SideBar },
});
</script>
