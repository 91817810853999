<template>
  <div class="company-preferences horizontal-padding" ref="el">
    <div v-if="expanded" style="position: relative">
      <div class="drawer-open-button-up" @click.stop="expanded = !expanded">
        <div style="height: 100%" icon>
          <v-icon style="height: 100%">mdi:mdi-chevron-up</v-icon>
        </div>
      </div>
      <div class="inputs">
        <v-row justify="space-between" dense class="input-fields">
          <v-col cols="3">
            <v-text-field
              prepend-inner-icon="fa-regular fa-globe"
              density="compact"
              :center-affix="true"
              :rounded="true"
              v-model="discoveryFields.domain"
              variant="outlined"
              persistent-placeholder
              placeholder="www.example.com"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              prepend-inner-icon="fa-regular fa-location-dot"
              density="compact"
              v-model="discoveryFields.location"
              :items="locations"
              label="Location"
              variant="outlined"
              :hide-details="true"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              prepend-inner-icon="fa-regular fa-language"
              density="compact"
              v-model="discoveryFields.language"
              :items="languages"
              label="Language"
              variant="outlined"
              :hide-details="true"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-text-field
              prepend-inner-icon="fa-regular fa-key-skeleton"
              density="compact"
              persistent-placeholder
              @keydown="handleDescriptiveWordKeypress"
              @paste="handleDescriptiveWordPaste"
              v-model="keyword"
              label="Descriptive words"
              placeholder="Add words here and press enter"
              variant="outlined"
              clearable
              outlined
            >
            </v-text-field>
            <div class="keyword-chips">
              <v-chip
                @click:close="() => removeDescriptiveWord(keyword as string)"
                :v-bind="keyword"
                :key="keyword"
                v-for="keyword in discoveryFields.descriptive_words"
                class="ma-1 keyword-chip"
                closable
              >
                {{ keyword }}
              </v-chip>
              <div class="inline" v-if="loadingWordSuggestions">
                <p class="inline" style="padding-left: 10px; padding-right: 20px; white-space: nowrap">
                  <v-progress-circular style="height: 20px" indeterminate color="#8200FF" />We're fetching
                  some suggestions for you
                </p>
              </div>
              <Transition>
                <div v-if="wordSuggestions && !loadingWordSuggestions" class="inline">
                  <v-chip
                    :key="word"
                    v-for="word in wordSuggestions.slice(0, 5)"
                    @click="(e: PointerEvent) => addSuggestion(e, word)"
                    class="ma-1 chip-add"
                  >
                    <v-icon style="margin-right: 5px">mdi:mdi-plus</v-icon>{{ word }}
                    <v-icon @click="() => removeWordSuggestion(word)" style="margin-left: 5px"
                      >mdi:mdi-close-circle-outline</v-icon
                    >
                  </v-chip>
                </div>
              </Transition>
              <v-chip
                v-if="
                  !loadingWordSuggestions &&
                  discoveryFields.descriptive_words &&
                  discoveryFields.descriptive_words?.length > 1
                "
                @click="toggleSuggestionModal"
                class="ma-1 chip-inverse"
              >
                Clear all
                <v-icon style="margin-left: 5px">mdi:mdi-close-circle-outline</v-icon>
              </v-chip>
              <MaireDialog
                text="Are you sure you want to clear everything?"
                :open="suggestionModalOpen"
                :onClose="toggleSuggestionModal"
                :fn="removeAllDescriptiveWords"
              >
              </MaireDialog>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="selector custom-density inputs" cols="2">
            <v-autocomplete
              v-if="!isViewer"
              placeholder="Select existing Discovery"
              persistent-placeholder
              item-title="name"
              item-value="id"
              density="compact"
              :items="discoveries"
              v-model="selectedDiscovery"
              hide-details
              variant="outlined"
              label="Review / edit existing discovery"
              clearable
              :menu-props="{ maxWidth: 500 }"
              @click:clear="clear"
              @update:modelValue="select"
            >
              <template v-slot:item="{ item, props }">
                <v-list-item v-bind="props">
                  <template #append>
                    <v-icon v-if="item.raw.is_active">far fa-clock</v-icon>
                    <v-icon class="ml-2" v-if="item.raw.labels?.length">far fa-tag</v-icon>
                  </template>
                  <template #title>
                    <p class="xs">{{ item.title }}</p>
                  </template>
                </v-list-item>
              </template>
              <template v-slot:clear>
                <v-icon @click.stop="clear">
                  fas fa-times-circle v-icon notranslate v-theme--myCustomLightTheme v-icon--size-default
                  v-icon--clickable
                </v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col class="buttons justify-end align-center" cols="10">
            <v-btn
              v-if="expanded"
              :disabled="!valid"
              @click="discover"
              class="edit-button"
              variant="outlined"
              color="primary"
            >
              Discover
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-container class="selection-container" v-if="!expanded">
      <div class="drawer-open-button-up" @click.stop="expanded = !expanded">
        <div style="height: 100%" icon>
          <v-icon style="height: 100%">mdi:mdi-chevron-down</v-icon>
        </div>
      </div>
      <v-row justify="space-around">
        <v-col cols="1">
          <div class="preference domain-field feather-effect">
            <v-icon class="inline">fa-regular fa-globe</v-icon>
            <span style="margin-left: 10px; max-width: 400px">{{ discoveryFields.domain }}</span>
          </div>
        </v-col>
        <v-col cols="1">
          <div class="preference">
            <v-icon class="inline">fa-regular fa-location-dot</v-icon>
            <p class="inline" style="margin-left: 10px">
              {{ discoveryFields.location }}
            </p>
          </div>
        </v-col>
        <v-col cols="1">
          <div class="preference">
            <v-icon class="inline">fa-regular fa-language</v-icon>
            <p class="inline" style="margin-left: 10px">
              {{ discoveryFields.language }}
            </p>
          </div>
        </v-col>
        <v-col cols="5">
          <div class="preference">
            <v-icon class="inline">fa-regular fa-key-skeleton</v-icon>
            <p class="inline" style="margin-left: 10px">
              {{ discoveryFields?.descriptive_words?.join(", ").slice(0, 60)
              }}{{ (discoveryFields?.descriptive_words || [])?.join(", ").length > 60 ? "..." : "" }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style scoped>
.unselected-label {
  filter: opacity(50%);
}
.domain-field {
  position: relative;
  overflow: hidden;
  margin-right: -40px;
}
span {
  font-size: 14px;
  font-family: "Nunito Sans";
}

.feather-effect:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40px;
  background: linear-gradient(to left, rgb(var(--v-theme-maireDarkBlue)), transparent);
}
.main_container {
  margin-right: -50px;
}
.secondary_container {
  margin-left: 50px;
  margin-top: 100px;
}
.search_container {
  width: 400px;
  position: relative;
  float: right;
  display: inline;
  margin-bottom: -4px;
}
.preference {
  /* put in the middle of parent */
  display: flex;
  align-items: center;
  padding-top: 12px;
  white-space: nowrap;
}

.revenue_card {
  margin-top: 5px;
}
.center {
  display: flex;
  align-items: center;
  margin-left: 0px;
  /* align to LEFT horizontally */
}

.company-preferences strong,
.company-preferences p {
  color: white;
}

.company-preferences {
  background-color: rgb(var(--v-theme-maireDarkBlue));
  /* padding: 14px 0px 0px 0px; */
  position: relative;
}

.custom-placeholer-color input::placeholder {
  color: rgb(0, 0, 136) !important;
  opacity: 1;
  font-size: 14px;
}

.descriptive_words_container {
  margin-right: -55px;
}

.inputs {
  position: relative;
  background-color: #f7f7f7;
  background-color: rgb(var(--v-theme-maireDarkBlue));
  padding-top: 24px;
  padding-bottom: 24px;
}

.inputs input {
  color: white !important;
}

.selection-container {
  position: relative;
  min-height: 80px;
  color: white;
  display: flex;
  align-items: top;
  justify-content: top;
}

.keyword-chips {
  margin-top: 0px;
  margin-bottom: 20px;
  max-height: 300px;
  position: relative;
  color: rgba(255, 255, 255, 0.9);
}

.keyword-chip {
  background-color: #8200ff;
}

.keyword-chip i {
  color: rgba(132, 0, 255, 0.7) !important;
}

.chip-inverse {
  background-color: white;
  border: 1px #8200ff solid;
  color: #8200ff;
}

.chip-inverse i {
  color: #a74bff;
  font-size: 18px;
}

.chip-add {
  background-color: white;
  border: 1px rgba(62, 84, 211, 0.7) solid;
  color: rgba(62, 84, 211, 0.7);
  cursor: pointer;
}

.chip-add i {
  color: rgba(62, 84, 211, 0.7);
  font-size: 18px;
}
.buttons {
  position: relative;
  align-self: center;
  font-weight: 600;
}

.selector {
  position: relative;
  align-self: center;
}

.close-button {
  position: absolute;
  top: 0px;
  right: 12px;
  margin-top: 20px;
  /* margin-right: -24px; */
  font-size: 24px;
  top: 0;
}
.edit-button {
  border-radius: 12px;
  border-style: none;
  height: 43px;
  color: white !important;
  float: right;
  text-transform: none;
  background-color: rgb(var(--v-theme-mairePink));
  z-index: 2;
}

.save-button {
  border-radius: 12px;
  border-style: none;
  height: 100%;
  margin-right: 10px;
  color: rgb(var(--v-theme-maireTeal)) !important;
  float: right;
  text-transform: none;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgb(var(--v-theme-maireTeal));
}
.save-button2 {
  margin-right: 10px;
  float: right;
}
.save-button i {
  margin-left: 8px;
  opacity: 1;
}
.edit-button:hover {
  filter: brightness(1.1);
}
</style>
<script lang="ts" setup>
import { ref, computed, onUnmounted, onMounted, defineProps, defineEmits, Ref, PropType, watch } from "vue";
import { SUPPORTED_COUNTRIES, SUPPORTED_LANGUAGES } from "@/components/helpers/geo_constants";
import MaireDialog from "@/components/MaireDialog/MaireDialog.vue";
import { useStore } from "@/store";
import { Discovery } from "@/store/modules/discovery/types";

const expanded = ref(true);
const discoveryFields: Ref<Partial<Discovery>> = ref({
  domain: "",
  location: "",
  language: "",
  descriptive_words: [],
});
const emit = defineEmits([
  "save",
  "select",
  "update",
  "changed",
  "validity-change",
  "clear",
  "toggle",
  "nameSuggestion",
  "discover",
]);
watch(
  discoveryFields,
  () => {
    if (expanded.value) {
      const nameSuggestion = `${discoveryFields.value.location}_${discoveryFields.value.language}_${
        discoveryFields.value.domain
      }_${discoveryFields.value.descriptive_words?.join(",")}`;
      emit("nameSuggestion", nameSuggestion);
      emit("update", discoveryFields.value);
    }
  },
  { deep: true }
);
watch(
  expanded,
  () => {
    emit("toggle", expanded.value);
  },
  { immediate: true }
);
const props = defineProps({ discovery: { type: Object as PropType<Discovery>, required: false } });
const selectedDiscovery: Ref<Discovery | null> = ref(null);
watch(
  () => props.discovery,
  () => {
    if (props.discovery?.name) {
      selectedDiscovery.value = props.discovery;
      discoveryFields.value.domain = props.discovery?.domain;
      discoveryFields.value.location = props.discovery?.location;
      discoveryFields.value.language = props.discovery?.language;
      discoveryFields.value.descriptive_words = [...(props.discovery?.descriptive_words ?? [])];
    } else {
      selectedDiscovery.value = null;
    }
  }
);

const valid = computed(() => {
  return (
    discoveryFields.value.location &&
    discoveryFields.value.language &&
    (discoveryFields.value.domain || discoveryFields.value.descriptive_words?.length || keyword.value)
  );
});
watch(valid, () => emit("validity-change", valid));
const discoveryChanged = computed(() => {
  if (!selectedDiscovery.value?.id) return true;
  if (JSON.stringify(selectedDiscovery.value?.labels ?? {}) !== selectedDiscovery.value?.originalLabels)
    return true;
  if (selectedDiscovery.value.domain !== discoveryFields.value.domain) return true;
  if (selectedDiscovery.value.language !== discoveryFields.value.language) return true;
  if (selectedDiscovery.value.location !== discoveryFields.value.location) return true;
  if (
    JSON.stringify(selectedDiscovery.value.descriptive_words) !==
    JSON.stringify(discoveryFields.value.descriptive_words)
  ) {
    return true;
  }

  return false;
});
watch(discoveryChanged, () => {
  emit("changed", discoveryChanged.value);
});
const suggestionModalOpen = ref(false);
const keyword = ref("");
const locations = SUPPORTED_COUNTRIES;
const languages = SUPPORTED_LANGUAGES;
const store = useStore();
const handleDescriptiveWordKeypress = (event: KeyboardEvent) => {
  if (["Enter", ","].includes(event.key)) {
    addDescriptiveWord();
    event.preventDefault();
  }
};
const handleDescriptiveWordPaste = (event: ClipboardEvent) => {
  event.preventDefault();
  const text = event.clipboardData?.getData("text") as string;
  descriptiveWordListToChips(text?.split(","));
};

const descriptiveWordListToChips = (words: string[]) => {
  if (words) {
    words.filter(Boolean).forEach((word) => {
      discoveryFields.value.descriptive_words?.push(word.trim());
      // store.dispatch({ type: "addDescriptiveWord", word });
    });
  }
};
const toggleSuggestionModal = () => {
  suggestionModalOpen.value = !suggestionModalOpen.value;
};
const toggleDrawer = () => {
  expanded.value = !expanded.value;
};
const discover = () => {
  expanded.value = !expanded.value;
  if (discoveryFields.value?.descriptive_words?.length === 0 && keyword.value.length > 0) {
    // store.dispatch({ type: "addDescriptiveWord", keyword: keyword.value });
    discoveryFields.value.descriptive_words?.push(keyword.value);
  }
  emit("discover");
};
const clear = () => {
  selectedDiscovery.value = null;
  emit("clear");
};
const select = (id: number) => {
  const discovery = discoveries.value.find((d) => d.id === id);
  if (discovery) {
    emit("select", discovery);
  }
};
const removeWordSuggestion = (suggestion: string) => {
  store.dispatch({ type: "removeWordSuggestion", suggestion: suggestion });
};
const removeDescriptiveWord = (keyword: string) => {
  discoveryFields.value.descriptive_words = discoveryFields.value.descriptive_words?.filter(
    (w) => w !== keyword
  );
  // store.dispatch({ type: "removeDescriptiveWord", keyword: keyword });
};
const removeAllDescriptiveWords = () => {
  toggleSuggestionModal();
  discoveryFields.value.descriptive_words = [];
  // store.dispatch({ type: "removeAllDescriptiveWords" });
};
const addSuggestion = (e: any, word: string) => {
  if (!e.target.className.includes("mdi-close")) {
    // store.dispatch({ type: "addDescriptiveWord", keyword: word });
    discoveryFields.value.descriptive_words?.push(word);
  }
};
const addDescriptiveWord = () => {
  const keywords = keyword.value
    .split(",")
    .map((word) => word.trim())
    .filter(Boolean);
  if (keywords?.length > 0) {
    store.dispatch("fetchWordSuggestions", {
      keyword: keywords[0],
      language: discoveryFields.value.language,
    });
    discoveryFields.value.descriptive_words?.push(...keywords);
    // keywords.forEach((keyword) => {
    //   store.dispatch({ type: "addDescriptiveWord", keyword });
    // });
    keyword.value = "";
  }
};
const el = ref<HTMLDivElement | null>(null);

const handleKeyUp = (event: KeyboardEvent) => {
  if (expanded.value && event.code === "Escape") {
    toggleDrawer();
  }
};
const handleKeyDown = (event: KeyboardEvent) => {
  if (expanded.value && (event.metaKey || event.ctrlKey) && event.code === "Enter") {
    discover();
  }
};
const userSearch = computed(() => {
  return store.state.userSearch;
});
onMounted(() => {
  el.value?.focus();
  el.value?.addEventListener("keyup", handleKeyUp);
  el.value?.addEventListener("keydown", handleKeyDown);
  if (userSearch.value) {
    discoveryFields.value = userSearch.value;
    if (!store.state.keywords.discoveryKeywords.length && valid) {
      store.dispatch("keywords/fetchRelatedKeywords", discoveryFields.value);
    }
  }
});
onUnmounted(() => {
  el.value?.removeEventListener("keyup", handleKeyUp);
  el.value?.removeEventListener("keydown", handleKeyDown);
});
const wordSuggestions = computed(() => {
  return store.state.company.wordSuggestions;
});

const discoveries = computed(() => {
  return store.state.discovery.discoveries;
});
const loadingWordSuggestions = computed(() => {
  return store.state.loading.loadingWordSuggestions;
});
const isViewer = computed(() => store.state.user.type === "viewer");
</script>
