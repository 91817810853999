<template>
  <div class="labelModal" v-if="!hideActions">
    <MaireDialog
      :onClose="() => (showModal = false)"
      :fn="addLabelToKeywords"
      title="Add label"
      :description="`Add label to ${selectedItems.length} keyword(s)`"
      confirmText="Add label"
      :open="showModal"
      :confirmDisabled="!labelToAdd"
    >
      <div class="inputs dark">
        {{ labelToAdd }}
        <v-combobox
          variant="outlined"
          :items="analysisLabels"
          item-value="text"
          item-title="text"
          placeholder="Cameras, Blue, High intent"
          autofocus
          class="category-input-field preventClick labelInput"
          density="compact"
          hide-details
          v-model="labelToAdd"
        />
      </div>
    </MaireDialog>
  </div>
  <div v-if="isLoading" class="animation">
    <wait-animation />
  </div>
  <div v-if="!isLoading" :class="`table table-container`">
    <v-row class="item-header-row paper-background-flat">
      <div v-if="actions" style="padding: 8px"></div>
      <div class="checkbox" style="margin-left: 4px" v-if="!hideActions">
        <MaireTooltip>
          <template #trigger>
            <v-checkbox
              @click="toggleAll"
              density="compact"
              :model-value="allSelected"
              color="purple"
              :disabled="items?.length > bulkActionLimit"
              disabled-message="As of now, you can only do actions for up to 100 000 items at a time."
              hide-details
            />
          </template>
          <template #content>
            <span>
              <p style="color: white" v-if="items?.length > bulkActionLimit">
                As of now, you can do bulk actions for up to {{ numberFormatter(bulkActionLimit, 2) }} items
                at a time.
              </p>
              <p style="color: white" v-else>
                Select all of the items in the below table in order to do actions in bulk.
              </p>
            </span>
          </template>
        </MaireTooltip>
      </div>
      <div
        :style="`position:relative; flex-basis: ${field.width}%`"
        :class="`cell item-header cell-${field.field} ${
          columnsWithFilters.includes(field.field)
            ? 'has-filter'
            : visibleFilter === field.field
            ? 'has-filter'
            : ''
        }`"
        v-for="field in filteredFields"
        :key="field.name"
      >
        <Transition>
          <div class="filter" v-if="allowFiltering(field.field) && visibleFilter === field.field">
            <KeywordFilter
              @save="afterFilterSave"
              :type="field.field"
              :showSelect="false"
              :items="allowedLocalFilters.includes(field.field) ? items : undefined"
              :field="allowedLocalFilters.includes(field.field) ? field : undefined"
              :selected="allowedLocalFilters.includes(field.field) ? localFilters?.[field.field] : undefined"
            />
          </div>
        </Transition>
        <div class="header-title-content">
          <v-icon class="sort-icon icon-left" @click="() => sortItems(field.field)">{{
            `${
              sortColumn === field.field
                ? sortAscending
                  ? "far fa-arrow-up-wide-short has-filter"
                  : "far fa-arrow-down-wide-short has-filter"
                : "far fa-arrow-up-arrow-down"
            }`
          }}</v-icon>
          <div class="name-value-container">
            <p class="xxs" style="margin-bottom: -4px !important" :id="field.field">{{ field.name }}</p>
            <p v-if="summaryValueForField(field)" :class="`summary-${field.field}`">
              <strong clas="xs">{{ summaryValueForField(field) }}</strong>
            </p>
          </div>
          <v-icon
            v-if="allowFiltering(field.field)"
            class="sort-icon icon-right"
            @click="toggleFilter(field.field)"
            >fa-solid fa-sliders</v-icon
          >
        </div>
      </div>
    </v-row>
    <Transition name="slide">
      <div class="buttons" v-if="selectedItems.length > 0 && !hideActions">
        <p class="s" style="padding-bottom: 12px">{{ selectedItems.length }} keyword(s) selected</p>
        <div class="actionButton">
          <MaireButton
            :disabled="isViewerUser"
            inverse
            :compact="true"
            :text="`Add label to keywords`"
            @click="showModal = true"
          />
        </div>
      </div>
    </Transition>
    <div :class="`tableBody ${sorting ? 'blur' : ''}`">
      <div v-if="visibleItems.length === 0" class="animation">
        <div class="animation center">No items found! 🤔</div>
      </div>
      <div v-else>
        <v-row
          class="item-row"
          v-for="item in visibleItems"
          :key="item.id"
          @click="(e: any) => handleRowClick(e, item)"
        >
          <ThreeDotMenu
            v-if="actions"
            :actions="actions.map((action) => ({ ...action, fn: () => action.fn(item) }))"
            :isOpen="threedotMenuOpen === item.id"
            @menu-opened="threedotMenuOpen = item.id"
            @menu-closed="threedotMenuOpen = undefined"
          />
          <div :class="`${actions ? 'has-menu' : ''}`"></div>
          <div class="checkbox" v-if="!hideActions">
            <v-checkbox density="compact" v-model="selectedItems" :value="item" color="purple" />
          </div>
          <div
            :style="`flex-basis: ${field.width}%`"
            :class="`item cell cell-${field.field}`"
            v-for="field in filteredFields"
            :key="field.name"
          >
            <div class="text-center">
              <component
                v-if="field.component"
                :is="field.component()"
                v-bind="{
                  fieldValue: item[field.field],
                  field: field,
                  item: item,
                  viewId: viewId,
                  selectedItems: selectedItems,
                  ...(field.componentProps ?? {}),
                }"
              />
              <span v-else>{{
                field.renderer &&
                field.renderer(getByDotNotation(item, field.field), item.historical_metrics?.length)
              }}</span>
            </div>
          </div>
        </v-row>
      </div>
    </div>
    <div class="numberSelectionSection">
      <div class="numberSelectionText inline">Results per page</div>
      <div class="numberSelector inline">
        <div class="numberSelection" v-if="!numberChoicesVisible" @click="toggleNumberChoices">
          <p>{{ elementsPerPage }}</p>
        </div>
        <div class="numberChoices" v-else>
          <div
            class="numberSelection"
            @click="() => setElementsPerPage(num)"
            :key="num"
            v-for="num in elemenstPerPageChoices"
          >
            <p>{{ num }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <div class="page-number">
        <div class="inline-element" :class="currentPage == 1 ? 'disabled' : 'page-change'" @click="firstPage">
          <v-icon aria-label="My Account" role="img" aria-hidden="false"> mdi:mdi-page-first </v-icon>
        </div>
        <div
          class="inline-element"
          :class="currentPage == 1 ? 'disabled' : 'page-change'"
          @click="previousPage"
        >
          <v-icon aria-label="My Account" role="img" aria-hidden="false"> mdi:mdi-chevron-left </v-icon>
        </div>
        <div class="inline-element" :key="i" v-for="i in numPages(currentPage)">
          <div
            :key="i"
            v-if="i != '...'"
            v-bind:class="[i == currentPage ? 'active' : 'page-change']"
            v-on:click="changePage(i as number)"
          >
            {{ i }}
          </div>
          <div class="three-dots" v-if="i == '...'">{{ i }}</div>
        </div>
        <div
          class="inline-element"
          :class="currentPage == lastPageNum() ? 'disabled' : 'page-change'"
          @click="nextPage"
        >
          <v-icon aria-label="My Account" role="img" aria-hidden="false"> mdi:mdi-chevron-right </v-icon>
        </div>
        <div
          class="inline-element"
          :class="currentPage == lastPageNum() ? 'disabled' : 'page-change'"
          @click="lastPage"
        >
          <v-icon aria-label="My Account" role="img" aria-hidden="false"> mdi:mdi-page-last </v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.blur {
  filter: blur(2px);
}
.has-menu {
  margin-left: 12px;
}
.item-header,
.item {
  flex: 1; /* You can remove the width percentage style if using flex */
  padding-right: 10px; /* maintain some spacing between columns, adjust as needed */
}

/* Ensure last child has no right padding, if desired */
.item-header:last-child,
.item:last-child {
  padding-right: 0;
}
.slide-leave-active,
.slide-enter-active {
  transition: all 0.3s ease-in-out;
}

.slide-enter-to,
.slide-leave-from {
  max-height: 500px;
  opacity: 1;
  height: 100%;
}

.slide-enter-from,
.slide-leave-to {
  max-height: 24px;
  opacity: 0;
  height: 0px;
}
.sort-icon {
  /* display: inline-block; */
  /* margin-right: 10px; */
  cursor: pointer;
}
.header-title-content {
  display: flex; /* Use Flexbox to lay out items in a row */
  align-items: center; /* Center items vertically within the container */
  justify-content: flex-start; /* Place the first icon to the start and the other to the end */
  height: 100%;
  flex-grow: 1;
}
.header-title-content > span {
  margin-left: 10px; /* Margin between the first icon and the span */
}
.header-title-content > .icon-right {
  margin-left: auto; /* Pushes the second icon to the far right */
  margin-right: 10px;
}
.v-leave-from {
  opacity: 1 !important;
}
.v-leave-to {
  opacity: 0 !important;
}
.v-enter-active {
  transition: opacity 0.5s ease !important;
}
.v-leave-active {
  transition: opacity 0.5s ease !important;
}
.has-filter .header-title,
.has-filter strong,
.has-filter,
.has-filter i,
i.has-filter {
  color: rgb(var(--v-theme-mairePurple)) !important;
}
.filter {
  width: 500px;
  right: 0px;
  margin-right: -250px;
  z-index: 99999;
  padding-top: 0px;
  top: 100%;
  border-radius: 5px;
  position: absolute;
  word-break: normal;
  opacity: 1;
  transition: opacity 0.2s ease;
}
.labelModal input {
  color: teal !important;
}
.buttons {
  /* position: fixed;
  bottom: 24px;
  right: 24px; */
  z-index: 2;
  height: 100%;
  margin-bottom: 12px;
}
.labelButton,
.actionButton {
  display: inline-block;
  padding-right: 12px;
}
.checkbox {
  max-width: 30px;
  margin-left: 0px;
  padding: 0px 8px !important;
  display: flex;
  align-items: center;
  /* padding: -24px !important;
  margin-top: -12px !important; */
}
.summary-ctr::after {
  content: "%";
}
.summary-potential_reached::after {
  content: "%";
}

.v-enter-active {
  transition: opacity 0.5s ease;
}

.v-leave-from,
.v-enter-from {
  opacity: 0;
}

.search_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 400px;
  float: right;
}

.search_bar {
  width: 400px;
  float: right;
}

.animation {
  padding: 50px;
}

.table-container {
  height: 100%;
  padding-top: 12px;
  margin-bottom: 100px;
}

.cell {
  padding: 0 0 0 10px;
  display: table;
  word-break: break-word;
  border-right: 1px solid rgb(var(--v-theme-maireGrey3));
  flex-flow: row nowrap;
  min-width: 0px;
}

.cell:last-child {
  border-right: none;
}
.item-header {
  display: flex;
  flex-grow: 1;
}

.text-center {
  display: table-cell;
  overflow: hidden;
  vertical-align: middle;
  text-align: left !important;
}

.progress-bar {
  filter: opacity(0.2);
}
.mdi:mdi-star {
  color: gold;
}

.disabled {
  filter: opacity(0.4);
}

.actions {
  float: right;
}

.table {
  font-size: 12px;
  min-height: 300px;
  padding-bottom: 50px;
}

.tableBody {
  height: 100%;
  overflow-y: auto;
  position: relative;
}

.tableBody::-webkit-scrollbar {
  display: none;
}

.page-change {
  cursor: pointer;
}

.inline-element {
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  height: 30px;
  width: 30px;
}

.inline {
  display: inline;
}

.numberSelectionSection {
  position: relative;
  right: 0px;
  bottom: -30px;
}

.numberSelector {
  position: relative;
  bottom: -5px;
  float: right;
  right: 0px;
  width: 30px;
}

.numberSelection {
  border: black 1px solid;
  background: white;
  margin-top: -1px;
  padding: 0px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: table;
}

.numberSelection p {
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}

.numberSelectionText {
  margin-right: 40px;
  position: absolute;
  bottom: 0px;
  float: right;
  right: 0px;
  width: 100px;
}

.page-number {
  display: inline-table;
  text-align: center;
  padding: 0px;
  text-anchor: end;
  bottom: 0px;
}

.active {
  background-color: #68d8b1;
  height: 28px;
  width: 28px;
  border: black 1px solid;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}

.item-row:hover strong {
  color: white !important;
}

.errorMessage {
  display: block;
  margin: auto;
  color: purple;
}

.pagination {
  position: relative;
  float: right;
  bottom: -90px;
  right: 0px;
}

.item-header-row {
  width: 100%;
  height: 100%;
  text-align: left;
  margin-bottom: 12px;
  margin-left: 0px;
  padding: 12px 0px !important;
}

.item-row {
  background-color: white;
  border-radius: 10px;
  margin-left: 0px;
  text-align: left;
  min-height: 40px;
  padding: 6px;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 6px;
  cursor: pointer;
}

.item-row:hover {
  background-color: rgb(var(--v-theme-maireDarkBlue));
  border-width: 2px;
}

.item-row:hover span,
.item-row:hover div,
.item-row:hover i {
  color: white;
}
.name-value-container {
  align-content: center;
  padding-left: 8px;
  height: 100%;
  max-height: 60px;
  overflow: hidden;
}
</style>
<script lang="ts">
import { Field } from "@/types";
import { defineComponent, PropType } from "vue";

const sortColumns = (a: any, b: any, sortColumn: string): number => {
  if (sortColumn === "position") {
    return (a[sortColumn] || 102) - (b[sortColumn] || 102);
  }
  if (typeof a[sortColumn] === "string") {
    return (a[sortColumn] as string).localeCompare(b[sortColumn]?.toString() as string);
  } else if (typeof a[sortColumn] === "number") {
    return (a[sortColumn] ?? 0) - (b[sortColumn] ?? 0);
  } else if (Object.keys(a[sortColumn] || {}).includes("high")) {
    return (a[sortColumn] as unknown as CPC).high - (b[sortColumn] as unknown as CPC).high;
  } else if (typeof a[sortColumn] === "object") {
    const aVal = JSON.stringify(a[sortColumn]?.[0]?.text ?? a[sortColumn]?.[0] ?? "");
    const bVal = JSON.stringify(b[sortColumn]?.[0]?.text ?? b[sortColumn]?.[0] ?? "");
    return (aVal as string)?.localeCompare(bVal?.toString() as string);
  } else {
    return (a[sortColumn] ?? (0 as number)) - (b[sortColumn] ?? (0 as number));
  }
};

export interface Item {
  [key: string]: any;
}
import MaireSelect from "@/components/MaireSelect/MaireSelect.vue";
import ThreeDotMenu from "@/components/ThreeDotMenu/ThreeDotMenu.vue";
import { Action } from "@/components/ThreeDotMenu/types";
import { AnalysisDetails, CheckboxState } from "@/store/modules/analysis/types";
import WaitAnimation from "../WaitAnimation/WaitAnimation.vue";
import MaireButton from "../ButtonBlock/MaireButton.vue";
import MaireDialog from "../MaireDialog/MaireDialog.vue";
import KeywordFilter from "../KeywordFilters/KeywordFilter.vue";
import { useStore } from "@/store";
import { CPC } from "@/store/modules/keywords/types";
import { numberFormatter } from "../helpers/helpers";
import MaireTooltip from "../MaireTooltip/MaireTooltip.vue";
export default defineComponent({
  name: "DataTable",
  components: {
    KeywordFilter,
    WaitAnimation,
    MaireSelect,
    MaireButton,
    MaireDialog,
    MaireTooltip,
    ThreeDotMenu,
  },
  props: {
    items: { required: true, type: Object as () => Item[] },
    totals: { required: false, type: Object as () => Record<string, number>, default: () => {} },
    fields: { required: true, type: Object as () => Field[] },
    isLoading: { required: false, type: Boolean, default: false },
    magicSearchEnabled: { required: false, type: Boolean, default: false },
    hideActions: { required: false, type: Boolean, default: false },
    actions: { required: false, type: Array as PropType<Action[]> },
    viewId: { required: false, type: Number },
    searchFields: {
      required: false,
      type: Array as () => string[],
      default: () => ["keyword", "labels"],
    },
    handleRowClick: { required: false, type: Function, default: () => {} },
    hideFields: {
      required: false,
      type: Array as () => string[],
      default: () => [],
    },
    localFiltersOnly: { required: false, type: Boolean, default: false },
    externalTotals: { required: false, default: false },
    defaultSortColumn: { required: false, type: String },
  },
  watch: {
    filterItemsBySearch() {
      if (this.currentPage > this.maxPages || this.currentPage === 0) {
        this.currentPage = 1;
      }
      this.selectedItems = [];
    },
    localFilters: {
      handler() {
        this.filteredItems = this.items.filter((item) => {
          return Object.entries(this.localFilters)
            .filter(([, values]) => values.length)
            .every(([field, values]) => {
              const value = item?.[field];
              if (field.includes("volume")) {
                const min = values[0];
                const max = values[1];
                if (min && value < min) return false;
                if (max && value > max) return false;
                return true;
              }
              if (typeof value === "object") {
                const thisField = this.fieldMap?.[field];
                const itemValues = value?.map((row: any) => row?.[thisField?.filtering_key] ?? row) ?? [];
                return itemValues.some((itemValue: any) => values.includes(itemValue));
              }
              return values.includes(value);
            });
        });
        if (!this.externalTotals) {
          this.calculateTotals();
        }
      },
      deep: true,
    },
    currentAnalysis() {
      if (this.currentAnalysis?.id) {
        this.selectedAnalysis = this.currentAnalysis;
        this.isAnalysisView = true;
      } else {
        this.selectedAnalysis = undefined;
        this.isAnalysisView = false;
      }
    },
    items() {
      this.selectedItems = [];
      if (this.sortColumn !== "volume") {
        this.filteredItems = this.items;
        this.sort();
      } else {
        this.filteredItems = this.items;
      }

      if (this.currentPage > this.maxPages) {
        this.currentPage = 1;
      }
      if (!this.externalTotals) {
        this.calculateTotals();
      }
    },
  },
  data: () => ({
    visibleFilter: "",
    numberChoicesVisible: false,
    sortAscending: false,
    isAnalysisView: false,
    localFilters: {} as { [key: string]: CheckboxState[] },
    localTotals: {} as { [key: string]: any },
    selecteditem: {} as Item,
    selectedItems: [] as Item[],
    sortColumn: "volume",
    elemenstPerPageChoices: [5, 10, 20, 50, 100, 200, 500],
    elementsPerPage: 10,
    currentPage: 1,
    bulkActionLimit: 100000,
    threedotMenuOpen: undefined,
    showModal: false,
    showNewAnalysisModal: false,
    showAnalysisModal: false,
    showRemovalModal: false,
    selectedAnalysis: undefined as AnalysisDetails | undefined,
    labelToAdd: "",
    filteredItems: [] as Item[],
    sorting: false,
  }),
  mounted() {
    this.filteredItems = this.items;
    if (this.filteredItems && !this.externalTotals) {
      this.calculateTotals();
    }
    if (this.currentAnalysis?.id) {
      this.selectedAnalysis = this.currentAnalysis;
      this.isAnalysisView = true;
    }
    if (this.defaultSortColumn) {
      this.sortColumn = this.defaultSortColumn;
      this.sortItems(this.defaultSortColumn);
    }
  },
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    currentAnalysis() {
      return this.store.state.analysis.currentAnalysis;
    },
    allowedLocalFilters() {
      return this.fields.filter((field) => field?.allow_filtering).map((field) => field.field);
    },
    analysisLabels() {
      if (!this.currentAnalysis?.id) {
        return Object.values(this.store.state.labels.labels) ?? [];
      }
      return this.store.state.labels.analysisLabels?.[this.currentAnalysis?.id] ?? [];
    },
    searchWord: {
      get() {
        return this.store.state.searchWord;
      },
      set(value: string) {
        this.store.commit("analysis/setSearchWord", value);
      },
    },
    filters() {
      return this.store.state.analysis.currentFilters;
    },
    analysisFilters() {
      return this.store.state.analysis.analyses;
    },
    filteredFields() {
      return this.fields.filter((field) => !this.hideFields.includes(field.field));
    },
    fieldMap() {
      return Object.fromEntries(this.fields.map((field) => [field.field, field]));
    },
    errorText() {
      return this.store.state.errorText;
    },
    publicPath() {
      return process.env.BASE_URL;
    },
    maxPages() {
      return Math.ceil(this.filteredItems.length / this.elementsPerPage);
    },
    visibleItems() {
      var start = (this.currentPage - 1) * this.elementsPerPage;
      var end = start + this.elementsPerPage;
      return this.filteredItems.slice(start, end);
    },
    allSelected() {
      return this.selectedItems.length > 0 && this.selectedItems.length === this.filteredItems.length;
    },
    labelFilterActive() {
      const fltrs = this.filters.labelFilters;
      const includeLabels = fltrs?.include.labels.filter((fltr) => fltr.checked).length > 0;
      return includeLabels;
    },
    columnsWithFilters() {
      const columns = [];
      if (!this.localFiltersOnly) {
        if (this.filters.volumeRange?.min || this.filters.volumeRange?.max) {
          columns.push("volume");
        }
        if (this.filters.trendingFilter.some((trend) => trend.checked)) {
          columns.push("trending");
        }
        if (this.filters.positionRange?.min || this.filters.positionRange?.max) {
          columns.push("position");
        }
        if (this.filters.languageFilter.some((lang) => lang.checked)) {
          columns.push("localization.language_code");
        }
        if (this.filters.countryFilter.some((country) => country.checked)) {
          columns.push("localization.location_name");
        }
        if (this.labelFilterActive) {
          columns.push("labels");
        }
        if (this.searchWord) {
          columns.push("keyword");
        }
      }
      Object.entries(this.localFilters).forEach(([field, values]) => {
        if (field.includes("volume")) {
          // for volume-fields we get a range [0, 100] : Array<number|undefined>
          // So we check whether there's actually any filters there e.g. [0, undefined] doesn't
          // Filter anything
          if (values.some(Boolean)) {
            columns.push(field);
          }
        } else if (values.length > 0) {
          columns.push(field);
        }
      });
      return columns;
    },
    selectedItemIds() {
      return this.selectedItems.map((item) => item.id);
    },
    userType() {
      return this.store.state.user.type;
    },
    isViewerUser() {
      return this.userType === "viewer";
    },
    editAccess() {
      return this.currentAnalysis?.edit_access;
    },
  },
  methods: {
    numberFormatter,
    summaryValueForField(field: Field) {
      const val = this.externalTotals ? this.totals?.[field.field] : this.localTotals?.[field.field];
      const postfixes: any = {
        keyword: " unique",
        trending: "%",
        cpc: "€",
      };
      if (val) {
        const formattedVal = numberFormatter(val, 2);
        return `${formattedVal}${postfixes?.[field.field] ?? field.postfix ?? ""}`;
      }
      return "";
    },
    afterFilterSave(data: { field: string; cbs: CheckboxState[] }) {
      // This only affects the table, so not suitable for e.g. analysis views where the keyword data is used as the source for other components as well.
      // e.g. graphs in the analysis page
      if (data.field) {
        this.localFilters[data.field] = data.cbs;
      }
      this.visibleFilter = "";
    },
    async calculateTotals() {
      let totals = {} as any;

      // Initialize variables needed for each type of aggregation once
      let sumFields: Record<string, number> = {};
      let avgFields: Record<string, { totalValue: number; count: number }> = {};
      let weightedAvgFields: Record<string, { total: number; impressions: number }> = {};
      let countDistinctFields: Record<string, Set<any>> = {};
      let numberOfItems: Record<string, number> = {};

      this.fields
        .filter((field) => field.aggregation)
        .forEach((field) => {
          if (field.aggregation === "sum") {
            sumFields[field.field] = 0;
          } else if (
            field.aggregation === "average" ||
            field.aggregation === "weighted_average_impressions"
          ) {
            avgFields[field.field] = { totalValue: 0, count: 0 };
            if (field.aggregation === "weighted_average_impressions") {
              weightedAvgFields[field.field] = { total: 0, impressions: 0 };
            }
          } else if (field.aggregation === "count_distinct") {
            countDistinctFields[field.field] = new Set();
          } else if (field.aggregation === "count") {
            numberOfItems[field.field] = 0;
          }
        });

      // Single loop over filteredItems
      this.filteredItems.forEach((item) => {
        this.fields.forEach((field) => {
          const fieldValue = parseFloat(item?.[field.field]) || 0;

          if (field.aggregation === "sum") {
            sumFields[field.field] += fieldValue;
          } else if (field.aggregation === "average") {
            // Determine correct numeric value for average
            let value = 0;
            if (field.field.toLowerCase() === "cpc") {
              value = parseFloat(item?.[field.field]?.high || "0");
            } else {
              value = fieldValue;
            }
            if (!isNaN(value)) {
              avgFields[field.field].totalValue += value;
              avgFields[field.field].count++;
            }
          } else if (field.aggregation === "weighted_average_impressions") {
            const impressions = item?.["impressions"] || item?.["volume"] || 0;
            weightedAvgFields[field.field].total += fieldValue * impressions;
            weightedAvgFields[field.field].impressions += impressions;
          } else if (field.aggregation === "count_distinct") {
            countDistinctFields[field.field].add(item?.[field.field]);
          } else if (field.aggregation === "count") {
            numberOfItems[field.field] += 1;
          }
        });
      });

      // Calculate results after the loop
      this.fields
        .filter((field) => field.aggregation)
        .forEach((field) => {
          if (field.aggregation === "sum") {
            totals[field.field] = sumFields[field.field];
          } else if (field.aggregation === "average") {
            if (avgFields[field.field].count > 0) {
              let avgResult = avgFields[field.field].totalValue / avgFields[field.field].count;
              if (field.field === "ctr" || field.field === "potential_reached") {
                avgResult *= 100;
              }
              totals[field.field] = avgResult;
            } else {
              totals[field.field] = 0;
            }
          } else if (field.aggregation === "weighted_average_impressions") {
            const { total, impressions } = weightedAvgFields[field.field];
            if (impressions > 0) {
              let avgResult = total / impressions;
              if (
                field.field === "ctr" ||
                field.field === "potential_reached" ||
                field.field === "trending"
              ) {
                avgResult *= 100;
              }
              totals[field.field] = avgResult;
            } else {
              totals[field.field] = 0;
            }
          } else if (field.aggregation === "count_distinct") {
            totals[field.field] = countDistinctFields[field.field].size;
          } else if (field.aggregation === "count") {
            totals[field.field] = numberOfItems[field.field];
          } else {
            totals[field.field] = 0;
          }
        });
      this.localTotals = totals;
    },
    allowFiltering(field: string) {
      const allowedFilters = [
        ...this.allowedLocalFilters,
        "volume",
        "trending",
        "potential",
        "position",
        "localization.language_code",
        "localization.location_name",
      ];
      return allowedFilters.includes(field);
    },
    getByDotNotation(obj: object, path: string) {
      return path.split(".").reduce((currentObject: { [key: string]: any }, key) => {
        return currentObject ? currentObject[key] : undefined;
      }, obj);
    },
    handleFilterSelect(selectedItem: any) {
      if (selectedItem?.name) {
        this.selectedAnalysis = selectedItem;
      }
    },
    toggleFilter(field: string) {
      if (this.visibleFilter === field) {
        this.visibleFilter = "";
      } else {
        this.visibleFilter = field;
      }
    },
    addLabelToKeywords() {
      // @ts-ignore
      const labelToAdd = typeof this.labelToAdd === "string" ? this.labelToAdd.trim() : this.labelToAdd.text;
      this.store.dispatch("keywords/addLabelToKeywords", {
        label: labelToAdd,
        kwIds: this.selectedItemIds,
        viewId: this.viewId,
      });
      this.showModal = false;
    },
    toggleAll() {
      if (this.selectedItems.length > 0) {
        this.selectedItems = [];
      } else {
        this.selectedItems = this.filteredItems;
      }
    },
    sortItems(field: string) {
      this.sorting = true;
      setTimeout(() => {
        const sortColumn = field;
        if (this.sortColumn === sortColumn) {
          this.sortAscending = !this.sortAscending;
        } else {
          this.sortAscending = false;
          this.sortColumn = sortColumn;
        }
        this.sort();
      }, 20);
    },
    sort() {
      if (!this.sorting) this.sorting = true;
      if (this.sortAscending) {
        this.filteredItems.sort((a, b) => sortColumns(a, b, this.sortColumn as string));
      } else {
        this.filteredItems.sort((a, b) => sortColumns(a, b, this.sortColumn as string));
        this.filteredItems.reverse();
      }
      this.sorting = false;
    },
    toggleNumberChoices() {
      this.numberChoicesVisible = !this.numberChoicesVisible;
    },
    setElementsPerPage(numberOfElements: number) {
      this.elementsPerPage = numberOfElements;
      this.numberChoicesVisible = false;
    },
    numPages(currentPage: number) {
      let pages = [1, currentPage - 1, currentPage, currentPage + 1, this.maxPages];
      pages = pages.filter((page) => page > 0 && page <= this.maxPages);
      const pageNumbers = Array.from(new Set(pages.sort((a, b) => a - b)));
      let prevNumb = 0;
      let pageNumbersWithDots = [];
      for (const numb of pageNumbers) {
        if (numb - prevNumb > 1) {
          pageNumbersWithDots.push("...");
        }
        pageNumbersWithDots.push(numb);
        prevNumb = numb;
      }
      return pageNumbersWithDots;
    },
    lastPageNum() {
      return Math.ceil(this.filteredItems.length / this.elementsPerPage);
    },
    nextPage() {
      if (this.currentPage != this.lastPageNum()) {
        this.currentPage = this.currentPage + 1;
      }
    },
    previousPage() {
      if (this.currentPage != 1) {
        this.currentPage = this.currentPage - 1;
      }
    },
    lastPage() {
      this.currentPage = this.lastPageNum();
    },
    firstPage() {
      this.currentPage = 1;
    },
    changePage(page: number) {
      this.currentPage = page;
    },
  },
});
</script>
