import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import router from "./router";
import { key, store } from "./store";
import axios from "axios";
import VueApexCharts from "vue3-apexcharts";
import VueScrollTo from "vue-scrollto";
import VueDOMPurifyHTML from "vue-dompurify-html";
import Vue3Lottie from 'vue3-lottie'

import * as Sentry from "@sentry/vue";

router.beforeEach(async (to, from, next) => {
  if (to.name === "login" || to.name === "reset_password") {
    next();
  } else if (store.state.user.email) {
    if (to.name !== "admin") {
      next();
    } else {
      if (store.state.user.type === "admin" || store.state.user.impersonator) {
        next();
      } else {
        next("/");
      }
    }
  } else {
    store.dispatch("loading/loadingApp", true);
    try {
      await store.dispatch("setup");
    } catch (e) {
      console.error(e);
    }

    store.dispatch("loading/loadingApp", false);
    if (to.name !== "admin") {
      next();
    } else {
      if (store.state.user.type === "admin" || store.state.user.impersonator) {
        next();
      } else {
        next("/");
      }
    }
  }
});

export const app = createApp(App);
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    app,
    dsn: "https://b0eeb223316a49f4804e86bf8106f6f1@o4505323957714944.ingest.sentry.io/4505323958960128",
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/app\.maire\.ai\/api/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      // new Sentry.Replay(),
    ],
    release: process.env.VUE_APP_SENTRY_RELEASE,
    // Performance Monitoring
    tracesSampleRate: 0.1 // Capture 100% of the transactions, reduce in production!
    // Session Replay
    // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
loadFonts();
app
  .use(VueApexCharts)
  .use(VueDOMPurifyHTML)
  .use(VueScrollTo, { offset: -80, lazy: false, easing: "linear" })
  .use(store, key)
  .use(Vue3Lottie)
  .use(router)
  .use(vuetify, {
    options: { customProperties: true }
  })
  .mount("#app");
